class disclaimers {

  constructor() {
    this.init();
  }

  init() {
    const ariaLabelFallback = 'View disclaimer, opens a dialog';
    let focusTrapped = false;
    let $opener = null;

    /**
     * Closes the disclaimer dialog
     */
    const closeDisclaimer = () => {
      $('.disclaimers[role="dialog"]').removeClass('active');
      $opener.focus();
    };

    /**
     * Opens the disclaimer dialog
     *
     * @param {string} content - the diclaimer content
     */
    const openDisclaimer = (content) => {
      const $dialog = $('.disclaimers[role="dialog"]');
      $dialog
        .find('.disclaimers-content p')
        .html(content);
      $dialog.addClass('active');
      $dialog.attr('tabindex', '0').focus();      
      trapFocus()
    };

    /**
     * Callback for when the disclaimer dialog is finished opening or closing
     */
    const onTransitionEnd = () => {
      const $dialog = $('.disclaimers[role="dialog"]');

      if ($dialog.hasClass('active')) {
        $dialog
          .attr('tabindex', '0')
          .focus();
        focusTrapped = true;
      } else {
        if ($opener != null) { 
          $opener.focus();
          $opener = null;
        }
        $dialog.removeAttr('tabindex');
        focusTrapped = false;
      }
    };

    // General cleanup on window unload
    window.addEventListener('unload', () => {
      $(document).off('keydown');
      $(document.body).off('click');
      $('.disclaimers[role="dialog"]').on('transitionend');
      $('.disclaimer-callout').off('click');
      $(window).off('scroll');
      $opener = null;
    }, { once: true, passive: true });

    // Document ready - bind event listeners
    $(document).ready(function() {
      // Ensure that all disclaimer callouts have an aria label
      $('.disclaimer-callout:not([aria-label])').attr('aria-label', ariaLabelFallback);

      $('.disclaimers[role="dialog"]').on('transitionend', onTransitionEnd);
      // open disclaimer
      // add .active class for animation
      $(document.body).on('click', '.disclaimer-callout', function(e) {
        e.preventDefault();

        $opener = $(this);

        let disContent = $opener.data('disclaimers-content');

        openDisclaimer(disContent);

        debug && console.log('disclaimersContent: ', disContent);
      }); //click END

      // close disc
      $('.disclaimers-close').on('click', function(e) {
        closeDisclaimer();
      }); //click END


      // On scroll
      // hide disclaimer
      $(window).scroll(function() {
        if ($('.disclaimers').hasClass('active')) {
          closeDisclaimer();
        }
      });

    }); //document ready

    // Function to trap focus within the modal
    function trapFocus() {
      const modal = $('.disclaimers.active');
      const focusableElements = modal.find(
        'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
      );
      const firstElement = focusableElements.first();
      const lastElement = focusableElements.last();
  
      modal.on('keydown', function(event) {
        if (event.key === 'Tab') {
          if (event.shiftKey) {
            if (document.activeElement === firstElement[0]) {
              event.preventDefault();
              lastElement.focus();
            }
          } else {
            if (document.activeElement === lastElement[0]) {
              event.preventDefault();
              firstElement.focus();
            }
          }
        }
      });
    }

  }
}